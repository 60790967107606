import axios from "axios";
import {useDownloadFile} from "../Hooks/useDownloadFile";
import React, {PropsWithChildren, useCallback, useState} from "react";
import {Alert, Backdrop, Button, CircularProgress, Snackbar} from "@mui/material";
import {useApplicationContext} from "../ApplicationContext";
import {GetFileCheckDownload} from "../apiClient/Api";
import Typography from "@mui/material/Typography";
import {Link} from "react-router";
import {SupportIcon} from "../SupportIcon";


export type DownloadFileButtonProps = {
    id: string,
    fileName: string
    children: React.ReactNode
}

export const DownloadFileButton = (props: DownloadFileButtonProps) => {
    const context = useApplicationContext();
    const [buttonState, setButtonState] = useState<"primary" | "secondary">(
        "primary"
    );
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [loadingIndicator, setLoadingIndicator] = useState<boolean>(false);
    const [showPreDownload, setShowPreDownload] = useState<boolean>(false);
    const [preDownloadData, setPreDownloadData] = useState<GetFileCheckDownload | undefined>(undefined);
    const [downloadProgress, setDownloadProgress] = useState<undefined | number>(0);
    const onErrorDownloadFile = useCallback(() => {
        setShowAlert(true);
        setLoadingIndicator(false);
    }, [showAlert, loadingIndicator]);
    const preDownloading = useCallback(() => {
        setLoadingIndicator(true);
        setButtonState("secondary");
    }, [buttonState, loadingIndicator]);
    const postDownloading = useCallback(() => {
        setButtonState("primary");
        setLoadingIndicator(false);
    }, [buttonState, loadingIndicator]);

    const getFileName = () => {
        return props.fileName;
    };
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    };

    const handleClosePreDownload = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setPreDownloadData(undefined);
        setShowPreDownload(false);
    };

    const apiUrl = `${context.baseUrl}/Version/File/${props.id}`
    const downloadFileFromApi = () => {
        setDownloadProgress(undefined);
        // throw new Error("uncomment this line to mock failure of API");
        return axios.get(
            apiUrl,
            {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${context.token}`, // add authentication information as required by the backend APIs.
                },
                onDownloadProgress: progressEvent => {
                    const total = progressEvent.total;
                    if(total != undefined){
                        const current = progressEvent.loaded;
                        const progressPercent = Math.round((current / total) * 100);
                        setDownloadProgress(progressPercent);
                    }
                },
            }
        );
    };

    const downloadPrepare = async () => {

        if(!context.acceptedTerms){
            context.setDisplayAcceptTerms(true)
        }

        try{
            const data = await context.api.version.versionCheckFileBeforeDownload(props.id);
            if(data && data.data)
                if (data.data.canDownload) {
                    await download();
                } else {
                    setShowPreDownload(true)
                    setPreDownloadData(data.data);

                }
        }
        catch (e) {
            context.apiError(e)
        }

    }


    const {url, download, name} = useDownloadFile({
        apiDefinition: downloadFileFromApi,
        preDownloading,
        postDownloading,
        onError: onErrorDownloadFile,
        getFileName,
    });
    return <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingIndicator}
        >
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                <Typography>Datei wird für den Download vorbereitet</Typography>
                <Typography variant={"caption"} color={"secondary"}>{getFileName()}</Typography>
                <SupportIcon 
                    isSpinning={downloadProgress === undefined}
                    percentage={downloadProgress}
                    size={120}
                    isWhite={true}
                />
            </div>
        </Backdrop>
        <Snackbar open={showPreDownload} autoHideDuration={10000} onClose={handleClosePreDownload}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}} >
            <Alert onClose={handleClosePreDownload} severity="error" sx={{width: '80%'}}>
               <Typography variant={"body2"}> {preDownloadData?.message}</Typography>
                <Button variant={"outlined"} color={"success"} component={Link} to={"/"}>Support Vertrag verlängern</Button>
                <Button variant={"outlined"} color={"error"} onClick={() => {
                    const element = document.getElementById(`V${preDownloadData?.maxMajorVersion}.${preDownloadData?.maxMinorVersion}.${preDownloadData?.maxPatchVersion}`);
                    if (element) {
                        // 👇 Will scroll smoothly to the top of the next section
                        element.scrollIntoView({behavior: 'smooth'});
                    }

                }} disabled={!(preDownloadData?.maxMajorVersion != null && preDownloadData?.maxMinorVersion != null && preDownloadData?.maxPatchVersion != null)}>Letzte verfügbare Version anzeigen</Button>
            </Alert>
        </Snackbar>
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                Fehler beim Download der Datei
            </Alert>
        </Snackbar>
        <div onClick={downloadPrepare} style={{
            cursor: 'pointer'
        }}>

            {props.children}
        </div>
    </>
}
