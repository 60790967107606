import { useParams } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { useApplicationContext } from 'ApplicationContext';
import { GetSupportContractData, SharedFileDto } from 'apiClient/Api';
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Typography, 
    Box,
    Button,
    IconButton,
    Stack,
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload, faDownload, faFileExcel, faFileWord, faFilePdf, faFileImage, faFileCode, faFileArchive, faFileAlt, faQuestionCircle, faFile, faGears, faSave } from '@fortawesome/pro-solid-svg-icons';
import {faAndroid} from '@fortawesome/free-brands-svg-icons';   
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { EUserGroup } from 'apiClient/Api';
import React from 'react';
import { SupportIcon } from '../../SupportIcon';
import axios, { CancelTokenSource } from 'axios';
import { DataGrid, GridColDef, GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Editor } from "@monaco-editor/react";

/**
 * FileSharing component displays shared files for a specific support contract
 * URL pattern: /filesharing/:supportContractId
 */
export const FileSharing = () => {
    const { supportContractId } = useParams<{ supportContractId: string }>();
    const [contract, setContract] = useState<GetSupportContractData | null>(null);
    const [files, setFiles] = useState<SharedFileDto[]>([]);
    const [error, setError] = useState<string | null>(null);
    const context = useApplicationContext();
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    // Neue States für den Upload-Dialog
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [customTitle, setCustomTitle] = useState('');

    // Neue States für Config-Upload
    const [configUploadError, setConfigUploadError] = useState<string | null>(null);

    // Neue States für Feedback
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    // Neue States für Upload/Download Progress
    const [isUploading, setIsUploading] = useState(false);
    const [isDownloading, setIsDownloading] = useState<string | null>(null); // Speichert die FileId des Downloads

    // Neue States für Progress
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [downloadProgress, setDownloadProgress] = useState<number>(0);

    // State für Cancel-Token
    const [uploadCancelToken, setUploadCancelToken] = useState<CancelTokenSource | null>(null);
    const [downloadCancelToken, setDownloadCancelToken] = useState<CancelTokenSource | null>(null);

    // Neue States für Delete-Dialog
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<SharedFileDto | null>(null);

    const [supportContracts, setSupportContracts] = useState<GetSupportContractData[]>([]);
    const [allFiles, setAllFiles] = useState<SharedFileDto[]>([]);

    // State für Loading hinzufügen
    const [isLoading, setIsLoading] = useState(true);

    // Neuen State für Spalten-Sichtbarkeit hinzufügen
    const [columnVisibility, setColumnVisibility] = useState<any>({
        supportContract: !supportContractId
    });

    // Add new state for editor
    const [editorOpen, setEditorOpen] = useState(false);
    const [editorContent, setEditorContent] = useState<string>('');
    const [editorLanguage, setEditorLanguage] = useState<string>('');
    const [selectedFileName, setSelectedFileName] = useState<string>('');
    const [currentEditingFileId, setCurrentEditingFileId] = useState<string>('');
    const [isEditorDirty, setIsEditorDirty] = useState(false);
    const [currentFile, setCurrentFile] = useState<SharedFileDto | null>(null);

    const hasOfficeAccess = context.userData?.userGroup === EUserGroup.OFFICE || 
                           context.userData?.userGroup === EUserGroup.ADMIN || 
                           context.userData?.userGroup === EUserGroup.SUPER_ADMIN;

    const isCustomer = context.userData?.userGroup === EUserGroup.CUSTOMER;

    // useTranslation Hook hinzufügen
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (supportContractId) {
                    // Fetch specific contract and its files
                    const contractResponse = await context.api.supportContract.supportContractGetSupportContract(supportContractId);
                    setContract(contractResponse.data);
                    const filesResponse = await context.api.api.fileSharingListFiles(supportContractId);
                    setFiles(filesResponse.data);
                } else {
                    // Fetch all files and contracts
                    const filesResponse = await context.api.api.fileSharingListAllFiles();
                    setAllFiles(filesResponse.data);
                    
                    // Fetch all support contracts
                    const contractsResponse = await context.api.supportContract.supportContractListSupportContracts();
                    setSupportContracts(contractsResponse.data);
                }
                setError(null);
            } catch (err: any) {
                console.error('Error fetching data:', err);
                setError('Ein Fehler ist aufgetreten');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [supportContractId, context.api]);

    // useEffect hinzufügen, um auf supportContractId-Änderungen zu reagieren
    useEffect(() => {
        setColumnVisibility({
            supportContract: !supportContractId
        });
    }, [supportContractId]);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file || !supportContractId) return;
        
        // Entferne die Dateiendung vom Standardtitel
        const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
        
        setSelectedFile(file);
        setCustomTitle(fileNameWithoutExtension);
        setUploadDialogOpen(true);
    };

    const handleConfigUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file || !supportContractId) return;

        setConfigUploadError(null);
        setIsUploading(true);
        setUploadProgress(0);

        const cancelToken = axios.CancelToken.source();
        setUploadCancelToken(cancelToken);

        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        if (!fileExtension || !['xml', 'json'].includes(fileExtension)) {
            setConfigUploadError('Nur XML oder JSON Dateien sind erlaubt');
            return;
        }

        try {
            await context.api.api.fileSharingUploadConfigFile({
                File: file,
                CustomTitle: file.name,
                SupportContractId: supportContractId
            }, {
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(progress);
                    }
                },
                cancelToken: cancelToken.token
            });

            const filesResponse = await context.api.api.fileSharingListFiles(supportContractId);
            setFiles(filesResponse.data);
            
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            
            showSuccess(t('fileSharing.configUploadSuccess'));
        } catch (err: any) {
            if (axios.isCancel(err)) {
                showSuccess(t('fileSharing.uploadCancel'));
            } else {
                console.error('Error uploading configuration:', err);
                setConfigUploadError(
                    err.response?.data?.message || 
                    'Beim Hochladen der Konfigurationsdatei ist ein Fehler aufgetreten'
                );
            }
            
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            setUploadCancelToken(null);
        }
    };

    const handleDeleteClick = (file: SharedFileDto) => {
        setFileToDelete(file);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!fileToDelete) return;

        try {
            await context.api.api.fileSharingDeleteFile(fileToDelete.id);
            
            // Refresh file list after deletion
            const filesResponse = await context.api.api.fileSharingListFiles(supportContractId!);
            setFiles(filesResponse.data);
            showSuccess(t('fileSharing.deleteSuccess'));
        } catch (err) {
            console.error('Error deleting file:', err);
        } finally {
            setDeleteDialogOpen(false);
            setFileToDelete(null);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setFileToDelete(null);
    };

    const handleDownload = async (fileId: string) => {
        if (!fileId) return;

        const foundFile = supportContractId ? files.find(f => f.id === fileId) : allFiles.find(f => f.id === fileId);
        setCurrentFile(foundFile || null);
        const fileExtension = foundFile?.fileExtension.toLowerCase();
        
        // If file is larger than 3MB, force download
        const MAX_EDITOR_SIZE = 3 * 1024 * 1024; // 3MB in bytes
        if (foundFile && foundFile.fileSize > MAX_EDITOR_SIZE) {
            await handleRegularDownload(fileId, foundFile);
            return;
        }
        
        // Check if file is XML or JSON
        if (fileExtension === '.xml' || fileExtension === '.json') {
            setIsDownloading(fileId);
            setDownloadProgress(0);
            
            try {
                const response = await context.api.api.fileSharingDownloadFile(fileId, {
                    onDownloadProgress: (progressEvent) => {
                        if (progressEvent.total) {
                            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            setDownloadProgress(progress);
                        }
                    },
                    //@ts-ignore
                    responseType: 'text',
                    headers: {
                        'Accept': fileExtension === '.xml' ? 'application/xml' : 'application/json'
                    }
                });

                try {
                    // Try to parse the content to validate it's really XML/JSON
                    if (fileExtension === '.xml') {
                        new DOMParser().parseFromString(response.data as any, "text/xml");
                    } else {
                        JSON.parse(response.data as any);
                    }
                    
                    setEditorContent(response.data as any);
                    setEditorLanguage(fileExtension.replace('.', '')); // Remove the dot
                    setSelectedFileName(foundFile ? `${foundFile.title}${foundFile.fileExtension}` : '');
                    setCurrentEditingFileId(fileId);
                    setEditorOpen(true);
                    setIsEditorDirty(false);
                } catch (parseError) {
                    // If parsing fails, fall back to regular download
                    console.error('File content parsing failed:', parseError);
                    await handleRegularDownload(fileId, foundFile);
                }

            } catch (err) {
                console.error('Error downloading file:', err);
                handleRegularDownload(fileId, foundFile);
            } finally {
                setIsDownloading(null);
                setDownloadProgress(0);
            }
            return;
        }

        await handleRegularDownload(fileId, foundFile);
    };

    const handleRegularDownload = async (fileId: string, currentFile: SharedFileDto | undefined) => {
        // Regular download for other file types
        setIsDownloading(fileId);
        setDownloadProgress(0);

        try {
            const response = await context.api.api.fileSharingDownloadFile(fileId, {
                onDownloadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setDownloadProgress(progress);
                    }
                },
                //@ts-ignore
                responseType: 'blob'
            });
            
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = url;
            
            // Use the original filename with extension from the file object
            const downloadFilename = currentFile 
                ? `${currentFile.title}${currentFile.fileExtension}`
                : 'download';
            link.download = downloadFilename;
            
            // Append link to body, click it, and remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            // Clean up the URL
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error('Error downloading file:', err);
        } finally {
            setIsDownloading(null);
            setDownloadProgress(0);
        }
    };

    const formatFileSize = (bytes: number | undefined) => {
        if (!bytes) return '0 B';
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
    };

    const formatDate = (dateString: string | undefined) => {
        if (!dateString) return '-';
        return format(new Date(dateString), 'dd.MM.yyyy HH:mm', { locale: de });
    };

    // Neue Funktion für das tatsächliche Hochladen
    const handleUploadConfirm = async () => {
        if (!selectedFile || !supportContractId) return;

        setIsUploading(true);
        setUploadProgress(0);

        const cancelToken = axios.CancelToken.source();
        setUploadCancelToken(cancelToken);

        try {
            await context.api.api.fileSharingUploadFile({
                File: selectedFile,
                CustomTitle: customTitle.trim(), // Nur der benutzerdefinierte Name ohne Endung
                SupportContractId: supportContractId
            }, {
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(progress);
                    }
                },
                cancelToken: cancelToken.token
            });

            const filesResponse = await context.api.api.fileSharingListFiles(supportContractId);
            setFiles(filesResponse.data);
            
            showSuccess(t('fileSharing.uploadSuccess'));
        } catch (err) {
            if (axios.isCancel(err)) {
                showSuccess(t('fileSharing.uploadCancel'));
            } else {
                console.error('Error uploading file:', err);
            }
        } finally {
            setIsUploading(false);
            setUploadProgress(0);
            setUploadDialogOpen(false);
            setSelectedFile(null);
            setCustomTitle('');
            setUploadCancelToken(null);
        }
    };

    // Funktion zum Abbrechen/Schließen des Uploads
    const handleUploadCancel = () => {
        if (isUploading && uploadCancelToken) {
            uploadCancelToken.cancel('Upload abgebrochen durch Benutzer');
        }
        // Reset aller Upload-bezogenen States
        setUploadDialogOpen(false);
        setSelectedFile(null);
        setCustomTitle('');
        setUploadCancelToken(null);
        setUploadProgress(0);
        setIsUploading(false);
        
        // Reset des File-Inputs
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    // Dialog-Close-Handler hinzufügen
    const handleDialogClose = (_event: object, reason: string) => {
        if (reason === 'escapeKeyDown' && !isUploading) {
            handleUploadCancel();
        }
    };

    // Funktion zum Anzeigen von Erfolgsmeldungen
    const showSuccess = (message: string) => {
        setSuccessMessage(t(message));
    };

    // Funktion zum Schließen der Erfolgsmeldung
    const handleSuccessClose = () => {
        setSuccessMessage(null);
    };

    // Neue Hilfsfunktion für Dateityp-Icons und Labels
    const getFileTypeInfo = (extension: string) => {
        const types: { [key: string]: { icon: any; label: string } } = {
            '.xml': { icon: faFileCode, label: t('fileTypes.config') },
            '.json': { icon: faFileCode, label: t('fileTypes.config') },
            '.xlsx': { icon: faFileExcel, label: t('fileTypes.excel') },
            '.doc': { icon: faFileWord, label: t('fileTypes.word') },
            '.docx': { icon: faFileWord, label: t('fileTypes.word') },
            '.pdf': { icon: faFilePdf, label: t('fileTypes.pdf') },
            '.jpg': { icon: faFileImage, label: t('fileTypes.image') },
            '.jpeg': { icon: faFileImage, label: t('fileTypes.image') },
            '.ioemodule': { icon: faFile, label: t('fileTypes.module') },
            '.rel.upg': { icon: faFileAlt, label: t('fileTypes.upgrade') },
            '.dbg.upg': { icon: faFileAlt, label: t('fileTypes.upgradeDebug') },
            '.apk': { icon: faAndroid, label: t('fileTypes.android') },
            '.zip': { icon: faFileArchive, label: t('fileTypes.zip') },
            '.exe': { icon: faGears, label: t('fileTypes.setup') }
        };

        return types[extension.toLowerCase()] || { 
            icon: faQuestionCircle, 
            label: t('fileTypes.unknown')
        };
    };

    // Prüfen, ob der aktuelle Nutzer die Datei löschen darf
    const canDeleteFile = (file: SharedFileDto) => {
        return hasOfficeAccess || file.createdById === context.userData?.id;
    };

    // Check if user can edit the file
    const canEditFile = (file: SharedFileDto) => {
        return hasOfficeAccess || file.createdById === context.userData?.id;
    };

    // Handle editor content changes
    const handleEditorChange = (value: string | undefined) => {
        if (value !== undefined && value !== editorContent) {
            setEditorContent(value);
            setIsEditorDirty(true);
        }
    };

    // Handle file update
    const handleUpdateFile = async () => {
        if (!currentEditingFileId || !editorContent) return;
        
        try {
            // Create a new Blob with the editor content
            const blob = new Blob([editorContent], { 
                type: editorLanguage === 'xml' ? 'application/xml' : 'application/json' 
            });
            
            // Create a File object
            const file = new File([blob], selectedFileName, { 
                type: editorLanguage === 'xml' ? 'application/xml' : 'application/json'
            });

            await context.api.api.fileSharingUpdateFile(currentEditingFileId, {
                file: file
            });
            
            showSuccess(t('fileSharing.updateSuccess'));
            setIsEditorDirty(false);
        } catch (err) {
            console.error('Error updating file:', err);
            // You might want to show an error message here
        }
    };

    // Modified close handler to check for unsaved changes
    const handleEditorClose = () => {
        if (isEditorDirty) {
            if (window.confirm(t('fileSharing.unsavedChanges'))) {
                closeEditor();
            }
        } else {
            closeEditor();
        }
    };

    // Helper function to close editor and reset states
    const closeEditor = () => {
        setEditorOpen(false);
        setEditorContent('');
        setEditorLanguage('');
        setSelectedFileName('');
        setCurrentEditingFileId('');
        setIsEditorDirty(false);
    };

    // Handle editor content download
    const handleEditorContentDownload = () => {
        if (!editorContent || !selectedFileName) return;
        
        // Create blob with current editor content
        const blob = new Blob([editorContent], { 
            type: editorLanguage === 'xml' ? 'application/xml' : 'application/json' 
        });
        
        // Create download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = selectedFileName;
        
        // Trigger download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Cleanup
        window.URL.revokeObjectURL(url);
    };

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: t('grid.columns.type'),
            width: 50,
            renderCell: (params: GridRenderCellParams<SharedFileDto>) => {
                const fileType = getFileTypeInfo(params.row.fileExtension);
                return (
                    <FontAwesomeIcon 
                        icon={fileType.icon} 
                        title={fileType.label}
                        size="lg"
                    />
                );
            }
        },
        {
            field: 'title',
            headerName: t('grid.columns.filename'),
            flex: 1,
        },
        {
            field: 'fileSize',
            headerName: t('grid.columns.size'),
            width: 120,
            valueFormatter: (params) => formatFileSize(params.value),
        },
        {
            field: 'created',
            headerName: t('grid.columns.created'),
            width: 160,
            valueFormatter: (params) => formatDate(params.value),
        },
        {
            field: 'modified',
            headerName: t('grid.columns.modified'),
            width: 160,
            valueFormatter: (params) => formatDate(params.value),
        },
        {
            field: 'createdByName',
            headerName: t('grid.columns.uploadedBy'),
            width: 150,
        },
        {
            field: 'supportContract',
            headerName: t('grid.columns.supportContract'),
            width: 200,
            renderCell: (params: GridRenderCellParams<SharedFileDto>) => {
                if (!supportContractId) {
                    const contract = supportContracts.find(c => c.id === params.row.supportContractId);
                    return contract ? (
                        <Link to={`/fileshare/${contract.id}`}>
                            {contract.contractId} - {contract.customerName}
                        </Link>
                    ) : '-';
                }
                return null;
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: t('grid.columns.actions'),
            width: 100,
            getActions: (params) => {
                const actions: JSX.Element[] = [];
                
                const isXmlOrJson = params.row.fileExtension.toLowerCase().endsWith('.xml') || 
                                   params.row.fileExtension.toLowerCase().endsWith('.json');
                const fileSize = params.row.fileSize || 0;
                const MAX_EDITOR_SIZE = 3 * 1024 * 1024; // 3MB
                
                if (isXmlOrJson && fileSize <= MAX_EDITOR_SIZE) {
                    actions.push(
                        <GridActionsCellItem
                            icon={isDownloading === params.row.id ? 
                                <SupportIcon size="40" percentage={downloadProgress} /> : 
                                <FontAwesomeIcon icon={faFileCode} />}
                            label="Edit"
                            onClick={() => handleDownload(params.row.id)}
                            disabled={isDownloading === params.row.id}
                            showInMenu={false}
                        />
                    );
                } else {
                    actions.push(
                        <GridActionsCellItem
                            icon={isDownloading === params.row.id ? 
                                <SupportIcon size="40" percentage={downloadProgress} /> : 
                                <FontAwesomeIcon icon={faDownload} />}
                            label="Download"
                            onClick={() => handleDownload(params.row.id)}
                            disabled={isDownloading === params.row.id}
                            showInMenu={false}
                        />
                    );
                }

                if (canDeleteFile(params.row)) {
                    actions.push(
                        <GridActionsCellItem
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            label="Delete"
                            onClick={() => handleDeleteClick(params.row)}
                            color="error"
                            showInMenu={false}
                        />
                    );
                }

                return actions;
            }
        },
    ];

    // Neue Custom NoRowsOverlay Komponente
    const CustomNoRowsOverlay = () => (
        <Stack height="100%" alignItems="center" justifyContent="center" spacing={1}>
            <FontAwesomeIcon icon={faFile} size="2x" opacity={0.3} />
            <Typography color="text.secondary">
                Keine Dateien verfügbar
            </Typography>
        </Stack>
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4">{t('fileSharing.title')}</Typography>
                {!error && supportContractId && (
                    <Stack direction="row" spacing={2}>
                        {isCustomer && (
                            <>
                                <input
                                    type="file"
                                    accept=".xml,.json"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleConfigUpload}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<FontAwesomeIcon icon={faUpload} />}
                                    onClick={() => {
                                        setConfigUploadError(null);
                                        fileInputRef.current?.click();
                                    }}
                                >
                                    {t('fileSharing.uploadConfig')}
                                </Button>
                            </>
                        )}
                        {hasOfficeAccess && (
                            <>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                                <Button
                                    variant="contained"
                                    startIcon={<FontAwesomeIcon icon={faUpload} />}
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    {t('fileSharing.uploadFile')}
                                </Button>
                            </>
                        )}
                    </Stack>
                )}
            </Stack>
            
            {/* Show config upload error if exists */}
            {configUploadError && (
                <Typography 
                    color="error" 
                    sx={{ 
                        mt: 2,
                        mb: 2,
                        p: 1,
                        bgcolor: 'error.light',
                        color: 'error.contrastText',
                        borderRadius: 1
                    }}
                >
                    {configUploadError}
                </Typography>
            )}
            
            {error ? (
                <Typography 
                    color="error" 
                    variant="h6" 
                    sx={{ 
                        textAlign: 'center', 
                        mt: 4,
                        p: 2,
                        bgcolor: 'error.light',
                        color: 'error.contrastText',
                        borderRadius: 1
                    }}
                >
                    {error}
                </Typography>
            ) : (
                <>
                    {contract && (
                        <div className="contract-info" style={{ marginBottom: '2rem' }}>
                            <Typography variant="h6">{t('fileSharing.contractInfoTitle')}</Typography>
                            <Typography>{t('fileSharing.contractId')}: {contract.contractId}</Typography>
                            <Typography>{t('fileSharing.customer')}: {contract.customerName}</Typography>
                        </div>
                    )}

                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={supportContractId ? files : allFiles}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                }
                            }}
                            columnVisibilityModel={columnVisibility}
                            onColumnVisibilityModelChange={(newModel) => setColumnVisibility(newModel)}
                            pageSizeOptions={[10, 25, 50]}
                            disableRowSelectionOnClick
                            loading={isLoading}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </Box>
                </>
            )}

            {/* Upload Dialog mit Progress */}
            <Dialog 
                open={uploadDialogOpen} 
                onClose={!isUploading ? handleDialogClose : undefined}
            >
                <DialogTitle>{t('fileSharing.uploadTitle')}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {t('fileSharing.selectedFile')} {selectedFile?.name}
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={t('fileSharing.customTitle')}
                            fullWidth
                            value={customTitle}
                            onChange={(e) => setCustomTitle(e.target.value)}
                            helperText={t('fileSharing.customTitleHelper')}
                            disabled={isUploading}
                        />
                        {isUploading && (
                            <Box sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: 'center',
                                mt: 2,
                                flexDirection: 'column',
                                gap: 1
                            }}>
                                <SupportIcon percentage={uploadProgress} />
                                <Typography variant="body2" color="textSecondary">
                                    {uploadProgress}%
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleUploadCancel} 
                        color="error"
                    >
                        {isUploading ? t('fileSharing.cancel') : t('fileSharing.close')}
                    </Button>
                    <Button 
                        onClick={handleUploadConfirm} 
                        variant="contained" 
                        disabled={!customTitle.trim() || isUploading}
                    >
                        {isUploading ? t('fileSharing.uploading') : t('fileSharing.upload')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete-Dialog hinzufügen */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
            >
                <DialogTitle>{t('fileSharing.deleteTitle')}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {t('fileSharing.deleteConfirm').replace('{title}', fileToDelete ? `${fileToDelete.title}${fileToDelete.fileExtension}` : '')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>
                        {t('fileSharing.deleteCancel')}
                    </Button>
                    <Button 
                        onClick={handleDeleteConfirm}
                        color="error"
                        variant="contained"
                    >
                        {t('fileSharing.deleteConfirmButton')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar für Erfolgsmeldungen */}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={handleSuccessClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleSuccessClose} 
                    severity="success" 
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>

            {/* Editor Dialog */}
            <Dialog
                open={editorOpen}
                onClose={handleEditorClose}
                fullScreen
            >
                <DialogTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{selectedFileName}</Typography>
                        <Stack direction="row" spacing={2} alignItems="center">
                            {currentFile && canEditFile(currentFile) && (
                                <Typography variant="body2" color="text.secondary">
                                    {isEditorDirty ? t('fileSharing.unsavedChangesIndicator') : ''}
                                </Typography>
                            )}
                            <Button
                                onClick={handleEditorContentDownload}
                                variant="outlined"
                                startIcon={<FontAwesomeIcon icon={faDownload} />}
                            >
                                {t('fileSharing.download')}
                            </Button>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Editor
                        height="100%"
                        language={editorLanguage}
                        value={editorContent}
                        onChange={handleEditorChange}
                        options={{
                            readOnly: !currentFile || !canEditFile(currentFile),
                            minimap: { enabled: true },
                            scrollBeyondLastLine: false,
                            automaticLayout: true,
                            fontSize: 14,
                            wordWrap: 'on',
                            lineNumbers: 'on',
                            folding: true,
                            foldingHighlight: true,
                            formatOnPaste: true,
                            formatOnType: true,
                            scrollbar: {
                                horizontal: 'visible',
                                useShadows: true,
                                alwaysConsumeMouseWheel: false
                            }
                        }}
                        loading={<SupportIcon percentage={100} />}
                    />
                </DialogContent>
                <DialogActions>
                    {currentFile && canEditFile(currentFile) && isEditorDirty && (
                        <Button 
                            onClick={handleUpdateFile}
                            variant="contained"
                            color="primary"
                            startIcon={<FontAwesomeIcon icon={faSave} />}
                        >
                            {t('fileSharing.save')}
                        </Button>
                    )}
                    <Button 
                        onClick={handleEditorClose}
                        variant="contained"
                        color="secondary"
                    >
                        {t('fileSharing.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FileSharing;
