import {Box, Link, Typography} from "@mui/material";
import version from "../version";
import React from "react";
import {useTranslation} from 'react-i18next';

export function Copyright(props: any) {
    const { t } = useTranslation();

    return (
        <Box sx={{
            borderRadius: 1,
            padding: 3,
            backgroundColor: (theme) => theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
        }}>
            <img 
                src={'/logo-2048.png'} 
                height={25} 
                style={{
                    display: 'block', 
                    margin: '0 auto', 
                    marginBottom: 12
                }}
            />
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {t('base.copyright.text')}{' '}
                <Link color="inherit" href="https://www.reichert-gmbh.com/" target={"_blank"}>
                    {t('base.company')}
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
                <br/>
                <Link target={"_blank"} href={"https://reichert-gmbh.com/impressum/"} color={"inherit"}>{t('base.imprint')}</Link> | <Link target={"_blank"} href={"https://www.reichert-gmbh.com/datenschutz/"} color={"inherit"}>{t('base.privacyPolicy')}</Link>
                <br/>
                <Typography variant="body2" color={"primary"} fontWeight={"bold"}>{t('base.copyright.version')}: {version}</Typography>
            </Typography>
        </Box>
    );
}




