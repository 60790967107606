import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {Link} from 'react-router';
import {Image, InfoRounded, Language} from '@mui/icons-material';

import {useApplicationContext} from '../../ApplicationContext';
import {useTranslation} from 'react-i18next';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useMediaQuery} from '@mui/material';
import {Copyright} from "../../Components/Copyright";
import LanguageButton from "../../Components/LanguageButton";
import { SupportIcon } from '../../SupportIcon';
export function SignInSide() {
    const context = useApplicationContext();
    const {enqueueSnackbar} = useSnackbar();
    const [email, setEmail] = useState('');

    const {t, i18n} = useTranslation();
    const theme = useTheme();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        var cookieAccept = data.get('cookies') as string;
        if (cookieAccept !== 'cookiesAccepted') {
            enqueueSnackbar(t('signin.cookiesWarning'), {
                variant: 'warning',
            });
            return;
        }

        var formData = {
            email: data.get('email') as string,
            password: data.get('password') as string,
            remember: data.get('remember') === 'rememberMe',
        };
        context.signIn(formData.email, formData.password, formData.remember);
    };

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <CssBaseline/>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(/login_background.jpeg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <SupportIcon />
                    <Typography component="h1" variant="h5">
                        {t('signin.login')}
                    </Typography>
                    <Grid container sx={{justifyContent: 'center', alignItems: 'center'}}>
                        <Grid width={50}>
                            <Avatar
                                sx={{
                                    color: (theme) => theme.palette.info.dark,
                                    backgroundColor: (theme) => theme.palette.info.light,
                                }}
                            >
                                <InfoRounded/>
                            </Avatar>
                        </Grid>
                        <Grid xs={10}>
                            <Typography variant="caption" color={(theme) => theme.palette.info.main}>
                                {t('signin.info')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            label={t('signin.emailLabel')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={t('signin.passwordLabel')}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="rememberMe" color="primary" id="remember" name="remember"/>}
                            label={t('signin.rememberMe')}
                        />
                        <br/>
                        <FormControlLabel
                            control={<Checkbox value="cookiesAccepted" color="primary" id="cookies"
                                               name="cookies"/>}
                            label={t('signin.cookiesAgreement')}
                        />

                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 1}}>
                            {t('signin.loginButton')}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            component={Link}
                            to={'/support'}
                            color={'secondary'}
                            sx={{mt: 1, mb: 2}}
                        >
                            {t('signin.needSupport')}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Button
                                    onClick={() => {
                                        context.api.user.userPasswordForgotten(email).catch(context.apiError).then(() => {
                                            enqueueSnackbar("Ihre Anfrage ist eingegangen. Falls Sie einen Nutzeraccount haben bekommen Sie in kürze eine E-Mail", {variant: "warning"})
                                        })
                                    }}
                                >
                                    {t('signin.forgotPassword')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button component={Link} to={'/SignUp'}>
                                    {t('signin.noAccount')}
                                </Button>
                            </Grid>
                        </Grid>

                        <br/>

                        <Typography align={'center'} variant={'caption'}>
                            {t('signin.cookiesInfo')}
                        </Typography>
                    </Box>
                </Box>
                <Copyright />
                {/* Floating Language Change Button */}
                <LanguageButton floating />
            </Grid>
        </Grid>
    );
}
