import React, {useState, useEffect} from 'react';
import {
    Collapse,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Toolbar,
    Drawer as MuiDrawer,
    Typography,
    Box
} from "@mui/material";
import {useLocation} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faServer,
    faMobile,
    faChartTreeMap,
    faTimelineArrow,
    faUsers,
    faQrcode,
    faKey,
    faFileCertificate,
    faArrowUp,
    faDatabase,
    faFileCircleQuestion,
    faGavel,
    faFilters,
    faCaretDown,
    faCaretUp,
    faChevronLeft,
    faGrid2
} from '@fortawesome/pro-solid-svg-icons'
import {EUserGroup, GetProductsData} from 'apiClient/Api';
import {RouterLink} from "Components/RouterLink"
import {useTranslation} from 'react-i18next';
import {useApplicationContext} from "ApplicationContext";
import { getDrawerWidth } from '../config/drawerConfig';
import { SupportIcon } from '../SupportIcon';
const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => {
        const { i18n } = useTranslation();
        const drawerWidth = getDrawerWidth(i18n.language);

        return {
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create(['width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(6),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(7),
                    },
                }),
            },
        }
    },
);

interface MyDrawerProps {
    open: boolean;
    handleDrawerToggle: () => void;
    isMobile: boolean;
}

/**
 * CollapsibleSection component to handle sections with collapsible lists.
 * @param props The properties for the component.
 */
const CollapsibleSection: React.FC<{
    section: string;
    icon: any;
    title: string;
    products: GetProductsData[];
    linkPrefix: string;
}> = ({section, icon, title, products, linkPrefix}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(location.pathname.startsWith(linkPrefix));

    /**
     * Toggles the open state of the section.
     */
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    if (products.length === 1) {
        return (
            <RouterLink to={`${linkPrefix}/${products[0].link!}`}
                        text={t(title)}
                        icon={icon}/>
        );
    }

    return (
        <>
            <ListItemButton onClick={handleToggle}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={t(title)}/>
                {isOpen ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faCaretDown}/>}
            </ListItemButton>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {products.map(product =>
                        <RouterLink to={`${linkPrefix}/${product.link!}`}
                                    text={product.name ?? ""}
                                    icon={icon}/>)}
                </List>
            </Collapse>
        </>
    );
};

const SupportDrawer: React.FC<MyDrawerProps> = ({open, handleDrawerToggle, isMobile}) => {
    const context = useApplicationContext();
    const [products, setProducts] = useState<GetProductsData[]>([]);
    const { i18n } = useTranslation();
    const drawerWidth = getDrawerWidth(i18n.language);

    useEffect(() => {
        context.api.products.productsGetProducts().then(data => {
            if (data && data.data)
                setProducts(data.data)
        })
    }, []);

    const {t} = useTranslation();

    return (
        <Drawer variant="permanent" open={open} sx={{width: drawerWidth}}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                    <SupportIcon size={60} />
                    <Typography variant="h6" sx={{ml: 2}} color={"primary"}>
                        System
                    </Typography>
                </Box>
                <IconButton onClick={handleDrawerToggle}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </IconButton>
            </Toolbar>
            <Divider/>
            {context.userData &&
                <List component="nav">
                    <RouterLink to={"/"} text={t("base.pages.dashboard")}
                                icon={<FontAwesomeIcon icon={faChartTreeMap}/>}/>

                    <CollapsibleSection
                        section="versions"
                        icon={<FontAwesomeIcon icon={faTimelineArrow}/>}
                        title="base.pages.versions"
                        products={products}
                        linkPrefix="/versions"
                    />

                    <CollapsibleSection
                        section="applications"
                        icon={<FontAwesomeIcon icon={faGrid2}/>}
                        title="base.pages.applications"
                        products={products}
                        linkPrefix="/applications"
                    />

                    {<RouterLink to={"/deviceManagement"}
                                 text={t("deviceManagement.title")}
                                 icon={<FontAwesomeIcon icon={faMobile}/>}/>}

                    <RouterLink to={"/fileshare"}
                                                text={t("base.pages.fileshare")}
                                                icon={<FontAwesomeIcon icon={faFileCircleQuestion}/>}/>
             

                    {context.userData!.userGroup! >= EUserGroup.ADMIN &&
                        <RouterLink to={"/terms"}
                                    text={t("base.pages.terms")}
                                    icon={<FontAwesomeIcon icon={faGavel}/>}/>}
                    {context.userData!.userGroup! <= EUserGroup.OFFICE &&
                        <>
                            <Divider sx={{my: 1}}/>
                            {!isMobile && <RouterLink to={"/admin/users"}
                                                      text={t("base.pages.users")}
                                                      icon={<FontAwesomeIcon icon={faUsers}/>}/>}
                            {!isMobile && <RouterLink to={"/admin/supportcontracts"}
                                                      text={t("base.pages.supportContracts")}
                                                      icon={<FontAwesomeIcon icon={faQrcode}/>}/>}
                            {!isMobile && <RouterLink to={"/admin/coredumps"}
                                                      text={"Core Dumps"}
                                                      icon={<FontAwesomeIcon icon={faQrcode}/>}/>}


                            <RouterLink to={"/admin/license_requests"}
                                            text={t("base.pages.licenseRequests")}
                                            icon={<FontAwesomeIcon icon={faFileCertificate}/>}/></> }

                            {context.userData!.userGroup! <= EUserGroup.DEVELOPMENT &&
                                <>
                                    <RouterLink to={"/admin/password_generator"}
                                                text={t("base.pages.passwordGenerator")}
                                                icon={<FontAwesomeIcon icon={faKey}/>}/>
                                    <RouterLink to={"/admin/license_generator"}
                                                text={t("base.pages.licenseGenerator")}
                                                icon={<FontAwesomeIcon icon={faFileCertificate}/>}/> 


                                {context.userData!.userGroup! <= EUserGroup.ADMIN &&<>

                                    <CollapsibleSection
                                        section="adminVersions"
                                        icon={<FontAwesomeIcon icon={faArrowUp}/>}
                                        title="base.pages.versions"
                                        products={products}
                                        linkPrefix="/admin/versions"
                                    />

                                    {context.userData!.userGroup! <= EUserGroup.ADMIN && !isMobile &&
                                        <>
                                            <RouterLink to={"/admin/systems"}
                                                        text={t("base.pages.systems")}
                                                        icon={<FontAwesomeIcon icon={faDatabase}/>}/>
                                            <RouterLink to={"/admin/filetypes"}
                                                        text={t("base.pages.fileTypes")}
                                                        icon={<FontAwesomeIcon icon={faFileCircleQuestion}/>}/>
                                            <RouterLink to={"/admin/terms"}
                                                        text={t("base.pages.terms")}
                                                        icon={<FontAwesomeIcon icon={faGavel}/>}/>
                                            <RouterLink to={"/admin/filterTags"}
                                                        text={t("base.pages.fileFilter")}
                                                        icon={<FontAwesomeIcon icon={faFilters}/>}/>
                                        </>
                                    }
                                </>
                            }
                            <Divider sx={{my: 1}}/>
                        </>
                    }
                </List>
            }
        </Drawer>
    );
}

export default SupportDrawer;
