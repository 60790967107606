import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Input,
    InputLabel, LinearProgress, MenuItem,
    Select, SelectChangeEvent, Typography
} from "@mui/material";
import {useApplicationContext} from "../ApplicationContext";
import {GetBuildsDto, GetFileTypeData, GetHostSystemData} from "../apiClient/Api";
import {applyInitialState} from "@mui/x-data-grid/hooks/features/columns/gridColumnsUtils";
import {determineMimeType} from "../Hooks/CustomFileType";
import { SupportIcon } from "SupportIcon";


export interface UploadVersionFileDialogProps {
    currentVersion: { major: number, minor: number, patch: number, productId: string } | undefined
    resetSelectedVersion: () => void
}

export const AutoUploadVersionDialog = (props: UploadVersionFileDialogProps) => {

    const context = useApplicationContext();
    const [builds,setBuilds] = useState<GetBuildsDto[]>([])
    const [buildId, setBuildId] = useState<string>("");
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false)
    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            context.api.teamCity.teamCityTeamCityBuilds().then(t => setBuilds(t.data)).catch(context.apiError)
        }
    }, [context.api.teamCity, context.apiError, initialized])


    const autoUploadFile = () => {
        setUploading(true);
        context.api.teamCity.teamCityDownloadFile({
            versionKey: props.currentVersion,
            buildId
        }).catch(context.apiError).finally(() => {
            setUploading(false)
            props.resetSelectedVersion();

        })
    }


    const handleChange = async (event: SelectChangeEvent) => {
        const _hostSystem = event.target.value as string;
        if (_hostSystem !== buildId) {
            setBuildId(event.target.value as string);
        }
    };





    const open = props.currentVersion != undefined;
    return <>
        {props.currentVersion != undefined &&

            <Dialog open={open}>
                <DialogTitle>
                    Datei Upload für
                    V{props.currentVersion.major}.{props.currentVersion.minor}.{props.currentVersion.patch}
                </DialogTitle>
                <DialogContent>
                    {uploading ? <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                        }}>
                        <Typography>Dateien werden hochgeladen</Typography>
                        <SupportIcon size={100} isSpinning />
                        </div>:
                    <FormControl fullWidth style={{marginTop: 15}}>
                        <InputLabel id="host-system-label">Build</InputLabel>
                        <Select
                            labelId="host-system-label"
                            id="host-system"
                            value={buildId}
                            label="Build"
                            onChange={handleChange}
                        >
                            {builds?.map(fileType => <MenuItem value={fileType.id!}>{fileType.name}</MenuItem>)}
                        </Select>
                    </FormControl>}

                </DialogContent>
                <DialogActions>
                    <Button color={"error"} onClick={props.resetSelectedVersion}>Abbrechen</Button>
                    <Button color={"success"} onClick={autoUploadFile} disabled={uploading}>Upload starten</Button>
                </DialogActions>
            </Dialog>

        }
    </>
}
