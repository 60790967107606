import React, { useState, useEffect } from 'react';
import { Container, Typography, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Api, LicenseResponseDto, LicenseStatus } from '../apiClient/Api';
import { useApplicationContext } from 'ApplicationContext';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { Link } from 'react-router';
import { set } from 'date-fns';

const LicenseRequests: React.FC = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState<LicenseResponseDto[]>([]);
  const [showUnapprovedOnly, setShowUnapprovedOnly] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const context = useApplicationContext();
  const { supportId } = useParams<{ supportId: string }>(); // Get supportCode from URL
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchRequests = async () => {
      const response = await context.api.service.serviceGetLicensesBySupportId(supportId!);
      setRequests(response.data);
    };

    fetchRequests();
  }, [supportId]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUnapprovedOnly(event.target.checked);
  };

  /**
   * Handles ordering a license.
   * @param {string} licenseId - The ID of the license to order.
   */
  const handleOrder = async (licenseId: string) => {
    try {
      await context.api.service.serviceOrderLicense(licenseId);
      const response = await context.api.service.serviceGetLicensesBySupportId(supportId!);
      setRequests(response.data);
      setErrorMessage(null);
    } catch (error: any) {
      console.error("Failed to order license:", error);
      if (error.response?.status === 401) {
        setErrorMessage(error.response.data.message || t('licenseOverview.unauthorizedError'));
      }
    }
  };

  /**
   * Handles revoking a license.
   * @param {string} licenseId - The ID of the license to revoke.
   */
  const handleRevoke = async (licenseId: string) => {
    try {
      await context.api.service.serviceRevokeLicense(licenseId);
      const response = await context.api.service.serviceGetLicensesBySupportId(supportId!);
      setRequests(response.data);
      setErrorMessage(null);
    } catch (error: any) {
      console.error("Failed to revoke license:", error);
      if (error.response?.status === 401) {
        setErrorMessage(error.response.data.message || t('licenseOverview.unauthorizedError'));
      }
    }
  };

  /**
   * Handles deleting a license.
   * @param {string} licenseId - The ID of the license to delete.
   */
  const handleDelete = async (licenseId: string) => {
    try {
      await context.api.service.serviceDeleteLicense(licenseId);
      const response = await context.api.service.serviceGetLicensesBySupportId(supportId!);
      setRequests(response.data);
      setErrorMessage(null);
    } catch (error: any) {
      console.error("Failed to delete license:", error);
      if (error.response?.status === 401) {
        setErrorMessage(error.response.data.message || t('licenseOverview.unauthorizedError'));
      }
    }
  };

  /**
   * Orders all licenses that are in the cart.
   * Handles unauthorized access error (401)
   */
  const orderAllLicenses = async () => {
    try {
      const inCartIds = requests
        .filter(license => license.status === LicenseStatus.InCart)
        .map(license => license.id)
        .filter(l => l != undefined) as string[];

      if (inCartIds.length > 0) {
        await context.api.service.serviceOrderLicenses(inCartIds);
        console.log("All licenses ordered successfully.");
        setOpenDialog(false);
        const response = await context.api.service.serviceGetLicensesBySupportId(supportId!);
        setRequests(response.data);
        setErrorMessage(null);
      } else {
        console.log("No licenses in cart to order.");
      }
    } catch (error: any) {
      console.error("Failed to order licenses:", error);
      if (error.response?.status === 401) {
        setErrorMessage(error.response.data.message);
      }
    }
  };

  const filteredRequests = showUnapprovedOnly
    ? requests.filter(request => !request.licenseGeneratedAt)
    : requests;

  return (
    <Container>
      {errorMessage && (
        <Typography color="error" sx={{ mt: 2, mb: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <Typography variant="h4" gutterBottom>
        {t('licenseOverview.title')}
      </Typography>
      <FormControlLabel
        control={<Checkbox checked={showUnapprovedOnly} onChange={handleFilterChange} />}
        label={t('licenseOverview.showUnapprovedOnly')}
      />
      <Button component={Link} to={`/licenses/${supportId}/request`} variant="contained" color="primary">
        {t('licenseOverview.requestNewLicense')}
      </Button>
      <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
        {t('licenseRequest.orderAll')}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('licenseOverview.requestId')}</TableCell>
              <TableCell>{t('licenseOverview.userName')}</TableCell>
              <TableCell>{t('licenseOverview.supportCode')}</TableCell>
              <TableCell>{t('licenseOverview.description')}</TableCell>
              <TableCell>{t('licenseOverview.requestedAt')}</TableCell>
              <TableCell>{t('licenseOverview.licenseGeneratedAt')}</TableCell>
              <TableCell>{t('licenseOverview.licenseCode')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests.map((request) => (
              <TableRow key={request.id}>
                <TableCell>{request.hostId}</TableCell>
                <TableCell>{request.userName}</TableCell>
                <TableCell>{request.supportCodeId}</TableCell>
                <TableCell>{request.description}</TableCell>
                <TableCell>{request.status === LicenseStatus.InCart ?  <Button color='primary' onClick={() => handleOrder(request.id!)}>{t('licenseOverview.order')}</Button> :dayjs(request.requestedAt).format('DD.MM.YYYY')} </TableCell>
                {request.status === LicenseStatus.LicenseGenerated && <> <TableCell>{request.licenseGeneratedAt ? dayjs(request.licenseGeneratedAt).format('DD.MM.YYYY') : '-'}</TableCell><TableCell>{request.licenseCode ?? '-'}</TableCell></> }
                {request.status !== LicenseStatus.LicenseGenerated && <TableCell colSpan={2}>
                  {(request.status ?? 0) === LicenseStatus.Ordered ? (
                    <Button fullWidth color='warning' onClick={() => handleRevoke(request.id!)}>{t('licenseOverview.revoke')}</Button>
                  ) : (request.status ?? 0) === LicenseStatus.Revoked ? (
                    <ButtonGroup fullWidth variant='contained'>
                      <Button color='primary' onClick={() => handleOrder(request.id!)}>{t('licenseOverview.reorder')}</Button>
                      <Button color='secondary' onClick={() => handleDelete(request.id!)}>{t('licenseOverview.delete')}</Button>
                    </ButtonGroup>
                  ) : (request.status ?? 0) === LicenseStatus.Rejected ? (
                    <Typography>{t('licenseOverview.rejected')}</Typography>
                  ) : (request.status ?? 0) === LicenseStatus.InCart ? (
                    <Button fullWidth color='secondary' onClick={() => handleDelete(request.id!)}>{t('licenseOverview.delete')}</Button>
                  ) : (
                    <ButtonGroup fullWidth variant='contained'>
                      <Button color='primary' onClick={() => handleOrder(request.id!)}>{t('licenseOverview.order')}</Button>
                      <Button color='warning' onClick={() => handleRevoke(request.id!)}>{t('licenseOverview.revoke')}</Button>
                    </ButtonGroup>
                  )}
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="order-all-dialog-title"
        aria-describedby="order-all-dialog-description"
      >
        <DialogTitle id="order-all-dialog-title">
          {t('licenseRequest.orderAll')}
        </DialogTitle>
        <DialogContent>
          {errorMessage ? (
            <DialogContentText color="error">
              {errorMessage}
            </DialogContentText>
          ) : (
            <>
              <DialogContentText id="order-all-dialog-description">
                {t('licenseGenerator.confirmOrderAllText')}
              </DialogContentText>
              <ul>
                {requests.filter(request => request.status === LicenseStatus.InCart).map(request => (
                  <li key={request.id}>{request.description}</li>
                ))}
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            {t('licenseGenerator.cancel')}
          </Button>
          {!errorMessage && (
            <Button onClick={orderAllLicenses} color="primary" autoFocus>
              {t('licenseGenerator.confirm')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LicenseRequests;