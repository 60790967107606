/**
 * Configuration object for drawer widths based on language
 */
export const drawerWidths: { [key: string]: number } = {
    de: 240, // Deutsche Wörter sind oft länger
    en: 250, // Englische Begriffe sind meist kürzer
    es: 280, // Spanische Begriffe mittlere Länge
    fr: 290  // Französische Begriffe etwas länger
};

/**
 * Get drawer width based on language code
 * @param language - The language code (de, en, es, fr)
 * @returns The drawer width for the specified language
 */
export const getDrawerWidth = (language: string): number => {
    return drawerWidths[language] || drawerWidths.en; // Fallback to English width
}; 