import React, { useState, useEffect } from 'react';
import { Container, ListItemAvatar, ListItemText, Paper, Typography, CircularProgress, Box, Chip, Stack, List, ListItem, ListItemIcon } from "@mui/material";
import { useApplicationContext } from "ApplicationContext";
import { useDocumentTitle } from "Hooks/useDocumentTitle";
import { GetFileTypeData, GetVersionFileData } from 'apiClient/Api';
import IconPlaceholder from 'Pages/Admin/IconPlaceholder';
import { useParams } from 'react-router';
import { useTranslation } from "react-i18next";
import Markdown from 'mui-markdown';
import DescriptionIcon from '@mui/icons-material/Description';
import { DownloadFileButton } from 'Components/DownloadFileButton';
import {SupportIcon} from "../../SupportIcon";

/**
 * Applications component to display a single application.
 * @returns {JSX.Element} The rendered component.
 */
export const ApplicationSingle = (): JSX.Element => {
    const { t } = useTranslation();
    const [fileTypeData, setFileTypeData] = useState<GetFileTypeData | null>(null);
    const [versionFiles, setVersionFiles] = useState<GetVersionFileData[]  | null>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const { application } = useParams<{ application: string }>();
    const context = useApplicationContext();
    const controller = context.api.fileType;
    useDocumentTitle(fileTypeData?.name ?? "Anwendung");




    useEffect(() => {
        if (application) {
            if( context.signInState){
                controller.fileTypeGetFileType(application).then(data => {
                    if (data.data) {
                        setFileTypeData(data.data);
                    } else {
                        setFileTypeData(null);
                    }
                    setLoading(false);
                }).catch(error => {
                    context.apiError(error);
                    setLoading(false);
                });

                controller.fileTypeGetFileTypeVersions(application).then(data => {
                    if (data.data) {
                        setVersionFiles(data.data);
                    } else {
                        setVersionFiles(null);
                    }
                    setLoading(false);
                }).catch(error => {
                    context.apiError(error);
                    setLoading(false);
                });
            }
            else{
                controller.fileTypeGetFileTypePublic(application).then(data => {
                    if (data.data) {
                        setFileTypeData(data.data);
                    } else {
                        setFileTypeData(null);
                    }
                    setLoading(false);
                }).catch(error => {
                    context.apiError(error);
                    setLoading(false);
                });
                setVersionFiles(null);
            }
        }
    }, [application, context.signInState]);

    if (loading) {
        return (
            <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
                <SupportIcon />
            </Container>
        );
    }

    if (!fileTypeData) {
        return (
            <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
                <Typography variant="h5" component={"h2"}>{t('base.pages.applicationNotFound')}</Typography>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Box display="flex" alignItems="center" sx={{mb: 2}}>
                <IconPlaceholder size={100} iconId={fileTypeData.iconId} />
                <Box sx={{ml: 2}}>
                    <Typography variant="h4" component={"h1"}>{fileTypeData.name}</Typography>
                    <Typography variant="caption" component={"p"}>{fileTypeData.hostSystemName}</Typography>
                    <Stack direction="row" 
                        spacing={1}
                        useFlexGap
                        sx={{ flexWrap: 'wrap' }}
                        >
                       {fileTypeData.filterTags?.map(tag => <Chip id={tag.identifier} label={tag.name} />)}
                    </Stack>
                </Box>
                </Box>
                <Markdown overrides={context.muiMarkdownOverrides}>
                    {fileTypeData.description}
                </Markdown>
                {versionFiles != null && versionFiles.length > 0 && (
                    <>
                        <Typography variant="h5" component={"h2"}>{t('base.pages.versions')}</Typography>
                        <List>
                            {versionFiles.map((versionFile) => (
                              <DownloadFileButton id={versionFile.id!} fileName={versionFile.fileName!}>
                                    <ListItem key={versionFile.id}>
                                        <ListItemIcon>
                                            <DescriptionIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${versionFile.versionKey?.major}.${versionFile.versionKey?.minor}.${versionFile.versionKey?.patch}`}
                                            secondary={versionFile.fileName}
                                        />
                                    </ListItem>
                                </DownloadFileButton>
                            ))}
                        </List>
                    </>
                )}
            </Paper>
        </Container>
    );
};