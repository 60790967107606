import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router";
import {BaseLayout} from "./BaseLayout";
import {AdminDashboard} from "./Pages/Admin/AdminDashboard";
import {AdminVersions} from "./Pages/Admin/AdminVersions";
import {Versions} from "./Pages/User/Versions";
import {AdminHostSystems} from "./Pages/Admin/AdminHostSystem";
import {AdminFileTypes} from "./Pages/Admin/AdminFileType";
import {useApplicationContext} from "./ApplicationContext";
import {SignInSide} from "./Pages/Public/SignIn";
import {AdminUsers} from "./Pages/Admin/AdminUsers";
import {AdminSupportContracts} from "./Pages/Admin/AdminSupportContracts";
import {QuickSupport} from "./Pages/QuickSupport/QuickSupport";
import {NotFound} from "./Pages/Public/NotFound";
import {ServiceCodeNew} from "./Pages/Admin/ServiceCodeNew";
import {AcceptTerms} from "./Pages/User/AcceptTerms";
import {AdminTerms} from "./Pages/Admin/AdminTerms";
import {ChangePassword} from "./Pages/User/ChangePassword";
import {EUserGroup} from "./apiClient/Api";
import {FilterTags} from "./Pages/Admin/FilterTags";
import {ServiceCode} from "./Pages/Admin/ServiceCode";
import {SignUp} from "./Pages/Public/SignUp";
import {HostDown} from "./Pages/Public/HostDown";
import {CoreDumpData} from "./Pages/Admin/CoreDumps/CoreDumpData";
import {CoreDumpsList} from "./Pages/Admin/CoreDumps/CoreDumpsList";
import CoreDumpUpload from "./Pages/Admin/CoreDumps/CoreDumpUpload";
import FileTypeEdit from "./Pages/Admin/FileTypeEdit";
import DeviceManagement from './Pages/User/DeviceManagement';
import { Applications } from 'Pages/User/Applications';
import { ApplicationSingle } from 'Pages/User/ApplicationSingle';
import { LicenseGenerator } from 'Pages/Admin/LicenseGenerator';
import { LicenseRequest } from 'Pages/LicenseRequest';
import AdminRequestedLicenses from 'Pages/Admin/AdminRequestedLicenses';
import LicenseRequests from 'Pages/LicenseOverview';
import FileSharing from 'Pages/User/FileSharing';
import { loader } from '@monaco-editor/react';

// Configure Monaco Editor
loader.config({
  paths: {
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.33.0/min/vs'
  }
});

function App() {
    const context = useApplicationContext();

    if(context.networkError){
        return <BrowserRouter>
            <Routes>
                <Route path="*" element={<HostDown/>}/>
            </Routes>
        </BrowserRouter>
    }


    return (
       <>
           {context.needChangePassword ? <ChangePassword /> : context.signInState && context.displayAcceptTerms ? <AcceptTerms/> : <>
                <BrowserRouter>

                    {context.signInState && context.userData !== undefined ? <>
                        <Routes>
                            <Route path="/" element={<BaseLayout/>}>
                                <Route path="" element={<AdminDashboard/>}/>
                                <Route path={"quicksupport/:id"} element={<QuickSupport/>}/>
                                <Route path="versions/:link" element={<Versions/>}/>
                                <Route path="terms" element={<AdminTerms/>}/>
                                <Route path="deviceManagement" element={<DeviceManagement/>}/>
                                <Route path="deviceManagement/:supportId" element={<DeviceManagement/>}/>
                                <Route path="fileshare" element={<FileSharing/>}/>   
                                <Route path="fileshare/:supportContractId" element={<FileSharing/>}/>              
                                <Route path="licenses/:supportId" element={<LicenseRequests/>}/>                                
                                <Route path="licenses/:supportId/request" element={<LicenseRequest/>}/>                                
                                <Route path="applications/:link" element={<Applications/>}/>
                                <Route path="applications/:link/:application" element={<ApplicationSingle/>}/>

                                <Route path="quicksupport" element={<QuickSupport/>}/>
                                {context.userData!.userGroup! > EUserGroup.DEVELOPMENT &&   <Route path="support" element={<QuickSupport/>}/>}
                                {context.userData!.userGroup! <= EUserGroup.DEVELOPMENT && <Route path="support" element={<ServiceCode/>}/>}
                                {context.userData!.userGroup! <= EUserGroup.OFFICE  &&
                                    <Route path="admin">
                                        <Route path="supportcontracts" element={<AdminSupportContracts/>}/>
                                        <Route path="coredumps" element={<CoreDumpsList/>}/>
                                        <Route path="coredumps/upload" element={<CoreDumpUpload/>}/>
                                        <Route path="coredumps/:id" element={<CoreDumpData/>}/>
                                        <Route path="users" element={<AdminUsers/>}/>
                                        <Route path="license_requests" element={<AdminRequestedLicenses/>}/>
                                        {/* Developer, Admin and SuperAdmin */}

                                        {context.userData!.userGroup! <= EUserGroup.DEVELOPMENT  &&
                                            <>
                                                <Route path="password_generator" element={<ServiceCodeNew/>}/>
                                                <Route path="license_generator" element={<LicenseGenerator/>}/>
                                                <Route path="versions/:link" element={<AdminVersions/>}/>
                                                {/* Only Admin and SuperAdmin */}
                                                {context.userData!.userGroup! <= EUserGroup.ADMIN &&
                                                    <>
                                                     
                                                        <Route path="systems" element={<AdminHostSystems/>}/>
                                                        <Route path="filetypes/:id" element={<FileTypeEdit/>}/>
                                                        <Route path="filetypes" element={<AdminFileTypes/>}/>
                                                        <Route path="terms" element={<AdminTerms/>}/>
                                                        <Route path="filterTags" element={<FilterTags/>}/>
                                                    </>}
                                            </>}
                                    </Route>}
                                <Route path="device-management" element={<DeviceManagement/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Route>
                        </Routes>

                    </> : <>
                        <Routes>
                            <Route path="/support" element={<QuickSupport/>}/>
                            <Route path="/quicksupport" element={<QuickSupport/>}/>
                            <Route path="/signup" element={<SignUp/>}/>
                            <Route path="applications/:link" element={<Applications/>}/>
                            <Route path="applications/:link/:application" element={<ApplicationSingle/>}/>

                            <Route path="*" element={<SignInSide/>}/>
                        </Routes></>
                    }

                </BrowserRouter>
            </>}
        </>
    );
}

export default App;
