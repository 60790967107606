import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardActions,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Select,
    TextField,
    Avatar,
    Box
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { useDocumentTitle } from "../../Hooks/useDocumentTitle";
import { useApplicationContext } from "../../ApplicationContext";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GetHostSystemData, AddFileTypeData, GetFileTypeData } from "../../apiClient/Api";
import { useNavigate } from 'react-router';
import IconPlaceholder from './IconPlaceholder';

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const AdminFileTypes = () => {
    useDocumentTitle("base.pages.fileTypes");

    const [rows, setRows] = useState<GetFileTypeData[]>([]);
    const [hostSystems, setHostSystems] = useState<GetHostSystemData[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isData, setIsData] = useState(false);
    const [open, setOpen] = useState(false);
    const [newAppName, setNewAppName] = useState("");
    const [newAppHostSystem, setNewAppHostSystem] = useState("");
    const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
    const [deleteEntryValue, setDeleteEntryValue] = useState("");
    const context = useApplicationContext();
    const controller = context.api.fileType;
    const history = useNavigate();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAddApp = async () => {
        const newApp: AddFileTypeData = {
            name: newAppName,
            hostSystemId: newAppHostSystem,
            description: "",
            filterTags: [],
            variants: [],
            packageId: ""
        };
        try {
            const response = await controller.fileTypeAddFileType(newApp);
            if (response.data) {
                setRows([...rows, response.data]);
                setOpen(false);
                setNewAppName("");
                setNewAppHostSystem("");
            }
        } catch (e) {
            context.apiError(e);
        }
    };

    const handleDeleteClick = (id: string) => () => {
        setSelectedRow(id);
    };

    const handleDeleteDialogClose = async (save: boolean) => {
        if (save && selectedRow) {
            const row = rows.find(r => r.id === selectedRow);
            if (row && deleteEntryValue === row.name) {
                try {
                    await controller.fileTypeDeleteFileType(selectedRow);
                    setRows(rows.filter(r => r.id !== selectedRow));
                    setSelectedRow(undefined);
                    setDeleteEntryValue("");
                } catch (e) {
                    context.apiError(e);
                }
            }
        } else {
            setSelectedRow(undefined);
            setDeleteEntryValue("");
        }
    };

    async function fetchData() {
        setIsFetching(true);
        try {
            const hostSystemsResponse = await controller.fileTypeGetHostSystems();
            setHostSystems(hostSystemsResponse.data);
            const fileTypesResponse = await controller.fileTypeGetFileTypes();
            setRows(fileTypesResponse.data);
        } catch (e) {
            context.apiError(e);
        }
        setIsFetching(false);
    }

    useEffect(() => {
        if (!isData) {
            setIsData(true);
            fetchData();
        }
    }, [isData]);

    const getLogoUrl = (logoId: string | null) => {
        // @ts-ignore
        var serverUrl = ServerConfig.SERVER_URL;
        return logoId ? `${serverUrl}/FileType/Image/${logoId}` : '';
    };

    return (
        <>
            <Dialog
                open={selectedRow !== undefined}
                onClose={() => handleDeleteDialogClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Löschen"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Möchten Sie den Eintrag wirklich löschen?
                    </DialogContentText>
                    {selectedRow && (
                        <>
                            <DialogContentText fontWeight={"bold"} id="alert-dialog-description">
                                Zum Löschen {rows.find(r => r.id === selectedRow)?.name} in das Textfeld eingeben
                            </DialogContentText>
                            <TextField
                                value={deleteEntryValue}
                                onChange={e => setDeleteEntryValue(e.target.value)}
                                fullWidth
                                margin={"dense"}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteDialogClose(false)} color={"error"}>Nein</Button>
                    <Button onClick={() => handleDeleteDialogClose(true)} color={"success"} autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Neue App hinzufügen</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        value={newAppName}
                        onChange={e => setNewAppName(e.target.value)}
                    />
                    <Select
                        label="Betriebssystem"
                        fullWidth
                        native
                        value={newAppHostSystem}
                        onChange={e => setNewAppHostSystem(e.target.value)}
                    >
                        <option value="">Nicht ausgewählt</option>
                        {hostSystems.map(hostSystem => (
                            <option key={hostSystem.id} value={hostSystem.id!}>
                                {hostSystem.name}
                            </option>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Abbrechen</Button>
                    <Button onClick={handleAddApp} color="primary">Hinzufügen</Button>
                </DialogActions>
            </Dialog>

            <Button color="primary" startIcon={<AddIcon />} onClick={handleOpen}>
                Neue App hinzufügen
            </Button>

            <Grid container spacing={2}>
                {rows.map((row) => (
                    <Grid item key={row.id} style={{ width: 250 }} >
                        <Card  onClick={() => history(`/admin/filetypes/${row.id}`)}>
                            <CardContent sx={{ display: 'flex', justifyContent: 'center' , flexDirection: 'column', alignItems: 'center'}}>
                            <Box style={{ marginBottom: 8 }}>
                                {row.iconId ? (
                                    <img src={getLogoUrl(row.iconId)} alt={row.name} style={{ width: 100 }} />
                                ) : (
                                    
                                        <IconPlaceholder />
                                )}
                                </Box>
                                <Typography variant="h6">{row.name}</Typography>
                                <Typography variant="caption">Betriebssystem:</Typography>
                                <Typography variant="body2">{hostSystems.find(data => data.id === row.hostSystemId)?.name}</Typography>
                                <Typography variant="caption">Package ID:</Typography>
                                <Typography variant="body2">{row.packageId ?? "-"} </Typography>
                            </CardContent>
                            <CardActions>
                                <IconButton onClick={() => history(`/admin/filetypes/${row.id}`)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton onClick={e => {
                                        e.stopPropagation();
                                    
                                    handleDeleteClick(row.id!)}
                                    }>
                                    <DeleteIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
